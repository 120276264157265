<template>
  <main class="ads-detail">
    <!-- banner -->
    <div class="ps-banner-advertising"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}"
          :style="{backgroundImage: `url(${(detail && detail.file) ? detail.file.path : require('../assets/images/img-default.jpeg')})`}">
      <div class="list-btn container">
        <button class="btn-icon btn-QA" @click="flagModalQA = true">
          <img src="../assets/images/icon/icon-qr-code.jpg" width="20" alt="icon">
        </button>
        <button class="btn-icon" @click="flagModalShare = true">
          <img src="../assets/images/icon/icon-share.svg" alt="icon">
        </button>
        <template v-if="isAuth">
          <button @click.prevent="handleLike"
                  class="btn-icon btn-like"
                  :class="{'active': detail && detail.is_favorite === 1}">
            <svg id="icon--heart" xmlns="http://www.w3.org/2000/svg" width="18.005" height="18" viewBox="0 0 18.005 18">
              <rect id="Rectangle_72" data-name="Rectangle 72" width="18" height="18" fill="none"/>
              <path id="heart" d="M16.474,1.454h0A4.9,4.9,0,0,0,14.865.377,4.972,4.972,0,0,0,12.944,0h0a5.273,5.273,0,0,0-1.949.377A4.791,4.791,0,0,0,9.34,1.454h0l-.313.305-.313-.305A4.854,4.854,0,0,0,7.088.377,5.2,5.2,0,0,0,5.111,0,4.972,4.972,0,0,0,3.19.377,4.9,4.9,0,0,0,1.581,1.454,5.246,5.246,0,0,0,.432,3.069,4.485,4.485,0,0,0,0,4.971a4.835,4.835,0,0,0,.4,1.9A4.9,4.9,0,0,0,1.489,8.489L8.4,15.236a.919.919,0,0,0,.239.17.708.708,0,0,0,.313.063.662.662,0,0,0,.294-.063,1.277,1.277,0,0,0,.257-.17L16.4,8.489a4.834,4.834,0,0,0,1.2-1.633A4.616,4.616,0,0,0,18,4.971a5.011,5.011,0,0,0-.368-1.884A4.723,4.723,0,0,0,16.474,1.454ZM15.389,7.43,9.028,13.621,2.666,7.43a3.408,3.408,0,0,1,0-4.917A2.964,2.964,0,0,1,3.8,1.768a3.753,3.753,0,0,1,1.315-.242,3.7,3.7,0,0,1,1.333.242,3.535,3.535,0,0,1,1.168.745l.864.843a.769.769,0,0,0,1.1,0l.791-.843h0a4.2,4.2,0,0,1,1.168-.718,3.653,3.653,0,0,1,1.407-.269h0a3.423,3.423,0,0,1,1.351.269,4.588,4.588,0,0,1,1.168.718h0A4.395,4.395,0,0,1,16.2,3.661a3.225,3.225,0,0,1,.276,1.31,3.136,3.136,0,0,1-.285,1.31A3.946,3.946,0,0,1,15.389,7.43Z" transform="translate(0.005 1.265)" fill="#282828"/>
            </svg>
          </button>
          <button class="btn-buy" @click="flagModalAddAdv = true">
            <plus-icon size="1.5x"></plus-icon>
            買うものリストに登録
          </button>
        </template>
      </div>

      <!-- content ads -->
      <div class="content-advertising container" v-if="detail">
        <!-- logo ads -->
        <figure>
          <img :src="(detail.file ? detail.file.path : '') | imageEmpty"
               :alt="detail.title">
        </figure>

        <!-- description ads -->
        <div class="description-ads">
          <h2 v-text="detail.title"/>
          <p v-if="detail.advertiser"
             v-text="detail.advertiser"/>

          <div class="tag">
            <span v-if="displayTagNew"
                  class="tag-red"
                  v-text="'NEW'"/>
            <span v-if="displayTagDeadline"
                  class="tag-orange"
                  v-text="'締切間近'"/>
            <span v-for="tag in detail.tags"
                  :key="tag.id"
                  v-text="tag.name"/>
          </div>
        </div>
      </div>

      <!-- background footer ripple -->
      <img class="bg-ripple" src="../assets/images/bg-ripple.svg" alt="bg ripple">
    </div>

    <!-- content home page -->
    <div class="home-content container"
         :class="{'padding-webview' : checkWebview()}">
      <!-- MARK COMPLETE MISSION -->
      <img v-if="detail && detail.mission_completed && detail.mission_completed.length"
           class="mark-complete"
           src="../assets/images/mark-complete.svg"
           alt="icon mark">
      <sidebar class="pc sidebar-advertising"/>

      <div class="main-content">
        <!-- point -->
        <div class="display-point">
          <div class="block-get-point">
            <button class="btn-get-point"
                    :class="{ 'fix-btn-get' : (checkWebview() && isAuth) }"
                    :disabled="detail && detail.is_disable_get_point === 1"
                    @click="beforeGetPoint">
              <!-- button get point PC if user logged in -->
              <template v-if="isAuth">
                <img src="../assets/images/icon/icon-coins.svg" alt="icon"> {{ textGetPoint }}
              </template>

              <!-- button login only sp if user not logged in yet -->
              <template v-else>
                無料会員登録はこちら
              </template>
            </button>

            <!-- link login -->
            <a v-if="!token"
               @click.prevent="openPageNative('Login')"
               class="btn-login">
              <ChevronRightIcon size="22"/>
              すでに会員の方はこちらからログイン
            </a>
          </div>

          <p v-if="detail">
            <span class="label-rate"
                  v-if="detail.is_campaign === 1
                        && checkDateBetween(detail.campaign_start_datetime, detail.campaign_end_datetime)">通常
              <template v-if="detail.point_type === 1">
                <del>{{ detail.normal_pointback_amount | numberWithCommas(',', 'P')}}</del>
              </template>
              <template v-else>
                <del>{{ detail.normal_pointback_rate | numberWithCommas(',', '%P') }}</del>
              </template>
            </span>

            <!--Campaign use-->
            <strong v-if="detail.is_campaign === 1
                          && checkDateBetween(detail.campaign_start_datetime, detail.campaign_end_datetime)">
              <!--fixed amount-->
              <template v-if="detail.point_type === 1">
                {{ detail.campaign_pointback_amount | numberWithCommas(',', 'P')}}
              </template>

              <!--fixed rate-->
              <template v-else>
                {{ detail.campaign_pointback_rate | numberWithCommas(',') }}<span>%</span>P
              </template>
            </strong>

            <!--Campaign not use-->
            <strong v-else>
              <!--fixed amount-->
              <template v-if="detail.point_type === 1">
                {{ detail.normal_pointback_amount | numberWithCommas(',', 'P')}}
              </template>

              <!--fixed rate-->
              <template v-else>
                {{ detail.normal_pointback_rate | numberWithCommas(',') }}<span>%</span>P
              </template>
            </strong>
          </p>
        </div>

        <!-- process get point -->
        <div class="wrap-process" v-if="detail">
          <div class="label-process">
            <h4>獲得条件</h4>
            <p v-html="detail.acquisition_condition_brief"/>
          </div>
          <div class="label-process">
            <h4>予定反映</h4>
            <p v-html="detail.addition_date"/>
          </div>
          <div class="label-process">
            <h4>確定反映</h4>
            <p v-html="detail.reflection_condition"/>
          </div>
        </div>

        <!-- content tab -->
        <div class="common-tab" id="header-tab">
          <!-- menu tab -->
          <ul class="menu-tab">
            <li :class="{'active': currentTab === item.id}"
                v-for="item in menuTab"
                :key="item.id"
                @click="currentTab = item.id">
              <span>{{ item.id }} ／</span>{{ item.name }}
            </li>
          </ul>

          <!-- content tab -->
          <div class="wrap-content-tab" v-if="detail">
            <div v-if="currentTab === 1"
                 v-html="detail.overview" />
            <div v-else-if="currentTab === 2"
                 v-html="detail.acquisition_condition" />
            <div v-else
                 v-html="detail.note"/>
          </div>

          <!-- button reference to tab -->
          <div class="button-ref">
            <!-- prev tab -->
            <button class="btn-pre btn-ref"
                    @click="--currentTab && scrollToTab()"
                    v-if="currentTab !== 1">
              <ChevronLeftIcon />
              <span v-if="currentTab === 2">概要へ</span>
              <span v-else>獲得条件へ</span>
            </button>

            <!-- line -->
            <span class="line"></span>

            <!-- next tab -->
            <button class="btn-next btn-ref"
                    @click="++currentTab && scrollToTab()"
                    v-if="currentTab !== 3">
                <span v-if="currentTab === 1">獲得条件へ</span>
                <span v-else>注意事項へ</span>
              <ChevronRightIcon/>
            </button>
          </div>
        </div>

        <!-- button get point only sp if user logged in -->
        <button v-if="isAuth && !checkWebview()"
                @click="beforeGetPoint"
                :disabled="detail && detail.is_disable_get_point === 1"
                class="btn-get-point only-sp">
          <img src="../assets/images/icon/icon-coins.svg" alt="icon">{{ textGetPoint }}
        </button>

        <!-- button login only sp if user not logged in yet -->
        <a @click.prevent="openPageNative('Login')"
                     v-else-if="!isAuth && !checkWebview()"
                     class="btn-get-point only-sp">
          無料会員登録はこちら
        </a>

<!--        &lt;!&ndash; Frequently asked questions &ndash;&gt;-->
<!--        <QuestionAnswer :list-question-answer="listQuestionAnswer" />-->

        <!-- list similar advertising -->
        <SimilarAdvertisement @change="fetchList($event)"
                              v-if="recommendedList"
                              :list="recommendedList"/>
      </div>
    </div>

    <!-- MODAL QA CODE -->
    <Modal v-if="flagModalQA"
           class="modal-center"
           @close-modal="flagModalQA = false">
      <!-- modal qr code -->
      <div class="modal-qr" slot="body">
        <p>以下のQRコードをスマートフォンで読み取って<br>
          アクセスしてください。</p>
        <qr-code-vue :value="currentURL"
                     class="qa-code"
                     :size="153"
                     level="L"/>
      </div>
    </Modal>

    <!-- MODAL QA CODE FOR CLICK URL -->
    <Modal v-if="flagModalClickUrl"
           class="modal-center"
           @close-modal="flagModalClickUrl = false">
      <!-- modal qr code -->
      <div class="modal-qr" slot="body">
        <p>本広告はスマホのみの対応となっております。<br>
        下記の二次元コードをスマホで読み取ることで、<br>
        お楽しみいただけます。</p>
        <qr-code-vue :value="currentURL"
                     class="qa-code"
                     :size="153"
                     level="L"/>
        <button
          class="btn-qr-close"
          @click="flagModalClickUrl = false"
        >
          閉じる
        </button>
      </div>
    </Modal>

    <!-- MODAL SHARE -->
    <Modal v-if="flagModalShare"
           class="modal-center"
           @close-modal="closeModal">
      <div class="modal-share"
           :class="{'bg-disabled' : showNoti}"
           slot="body">
        <p class="title-modal">シェアされた情報を見てお友達がChibiJobに登録したら、<br>友達紹介特典としてあなたに100ポイントプレゼント！</p>

        <!-- SHARE ON TWITTER -->
        <template>
          <button
            class="btn-social twitter"
            @click.prevent="openModalShareTwitter"
            >
            <img src="../assets/images/icon/icon-x.svg" alt="icon">X(旧Twitter)で紹介
          </button>
        </template>

        <!-- SHARE ON LINE -->
        <ShareNetwork
          v-if="!checkWebview()"
          class="btn-social btn-line"
          network="Line"
          :title="'ポイ活サイトのCHIBIJOB（ちびじょぶ）でお小遣いGET！！\n' + detail.title +
                    '\nミッション達成でポイントGETしよう！'"
          :url="shareLineUrl">
          <img src="../assets/images/icon/icon-line.svg" alt="icon">LINEで紹介
        </ShareNetwork>

        <!-- SHARE LINE ON APP -->
        <button
          v-else
          class="btn-social btn-line"
          @click.prevent="handleShareLine">
          <img src="../assets/images/icon/icon-line.svg" alt="icon">LINEで紹介
        </button>

        <!-- link share -->
        <div v-if="isAuth"
             class="wrap-copy">
          <input ref="url1"
                 :value='currentURL + "?invite="+ $store.state.auth.profile.ref_code'/>
          <button @click="copyText('url1', $event)">
            <clipboard-icon size="1.5x"/>
            コピーする
            <span>copied!</span>
          </button>
        </div>
      </div>
    </Modal>

    <!-- MODAL BLOCK TRACKING WEB -->
    <Modal v-if="flagModalBlockTracking" @close-modal="flagModalBlockTracking= false">
      <div class="modal-block-tracking" slot="body">
        <!-- title -->
        <h3 class="pc">ポイント獲得のために必ずご確認ください</h3>
        <h3 class="sp">必ずご確認ください</h3>
        <p class="text-des">Safariのセキュリティ機能「Intelligent Tracking Prevention（ITP）」がONとなっている場合、ちびじょぶと広告サイトの連携に失敗する場合があります。<br>
          そのため、ITPの設定を“OFF”にしていただけますようお願いいたします。<br>
          新規で端末を購入・ソフトウェアをアップデートされますと、はじめからITPがONの状態となっておりますので、ご注意ください。</p>

        <!-- note for SP -->
        <div class="note sp">
          <span>iPhoneの方</span>
          <p>設定→Safari→「サイト越えトラッキングを防ぐ」を【OFF】にする</p>
          <img src="../assets/images/tracking-phone.png" alt="tracking">
        </div>

        <!-- note for PC -->
        <div class="note pc">
          <span>MACの方</span>
          <p>プライバシー→「サイト越えトラッキングを防ぐ」のチェックを【外す】</p>
          <img src="../assets/images/tracking-pc.png" alt="tracking">
        </div>
      </div>

      <!-- footer modal -->
      <button slot="footer"
              @click="handleModalTracking"
              class="btn-confirm">確認したので利用を再開する</button>
    </Modal>

    <!-- MODAL ADD ADVERTISING TO CART -->
    <Modal v-if="flagModalAddAdv" @close-modal="flagModalAddAdv = false">
      <!-- body modal -->
      <ValidationObserver
        slot="body"
        tag="form"
        ref="observer"
        class="form">

        <!-- Error server -->
        <ul v-if="errors.length"
            class="error__server">
          <li>{{ errors }}</li>
        </ul>

        <div class="form__cart">
          <div class="form__cart__group">
            <!-- title -->
            <div class="form__control">
              <label>
                タイトル
                <span class="required"
                      v-text="'必須'"/>
              </label>
              <div class="form__control__input">
                <InputField v-model="form.title"
                            class="w-100"
                            rules="required|max:200"
                            field="タイトル"/>
              </div>
            </div>

            <!-- advertising -->
            <div class="form__control form__control--select ">
              <label>
                関連広告
              </label>

              <div class="form__control__input">
                <p class="title-select-ads">{{ detail.title }}</p>
              </div>
            </div>

            <!-- date add -->
            <div class="form__control form__control--select ">
              <label>
                期限
              </label>

              <div class="form__control__input">
                <InputDatepicker v-model="form.deadline_datetime"
                                 class="w-30 time"
                                 field="期限"/>
              </div>
            </div>

            <!-- note add -->
            <div class="form__control">
              <label>
                メモ
              </label>

              <div class="form__control__input">

                <TextAreaField v-model="form.note"
                               :rows="4"
                               :max-length="2000"
                               rules="max:2000"
                               class="w-100"
                               field="メモ"
                               vid="note"/>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>

      <!-- footer modal -->
      <button @click="validateBeforeSubmit"
              slot="footer"
              class="btn btn__submit">
        登録する
        <ChevronRightIcon size="24"/>
      </button>
    </Modal>

    <!-- MODAL ERROR WHEN SHARE TWITTER -->
    <Modal @close-modal="handleCloseError"
           class="modal-center modal-exchange-error"
           v-if="flagErrorShare">
      <div slot="body">
        <p>エラーが発生しました。<br>時間をおいてから再度お試しください。</p>
      </div>
    </Modal>

    <Modal @close-modal="flagModalCautionAdv = false"
           class="modal-center"
           v-if="flagModalCautionAdv">
      <div slot="body">
        <div class="caution-description" v-html="detail.caution_description" />
      </div>
      <div slot="footer">
      <button
            @click.prevent="handleGetPoint"
            type="submit"
            class="btn btn__submit btn-caution__submit"
            >
          <img src="../assets/images/icon/icon-coins.svg" alt="icon">
           {{ textGetPoint }}
      </button>
      </div>
    </Modal>
     <!-- MODAL RECOMMEND BEFORE SHARE AND SHARED TWITTER -->
    <Modal @close-modal="closeModalRecommended"
           class="modal-center cate-pink"
           v-if="flagModalRecommend">
      <div slot="body" class="responsive-height">
        <div class="block-vertical">
          <ListItem :list="listRecommendModal" titleModal="スタッフいちおし!" hasBackground  mode="vertical" isChangeBackground isInModal keepModal/>
        </div>
            <template>
              <p v-if="!isShare && !isVideoComplete && !isShowSpinner" class="content-before-share">動画を見てシェアすると5ポイントゲット！</p>
              <p v-if="isShare" class="content-before-share">X(旧Twitter)へポストの送信に成功しました。</p>
              <p v-if="isShowSpinner" class="content-before-share">動画広告取得中...</p>
            </template>
            <template>
              <VideoAdvertise
                v-if="!isVideoComplete && !isShare"
                v-show="!isShowSpinner"
                @adAPI="onInitAPI"
                @adVideoComplete="videoComplete"
                @adVideoInit="onShowSpinner"
                @adVideoStarted="onStartVideo"
                @adError="onAdError"
                @adNotResponse="onAdError"
                @adStartWaiting="onStartWaiting"
                @adDoneWaiting="onDoneWaiting"
                @adLoadError="loadVideoError"
              />

              <p v-if="isVideoComplete && !isLoadVideoError" class="content-before-share">動画視聴が完了しました。Xでシェアして5ポイントをゲットしよう！</p>
              <p v-else-if="isVideoComplete && isLoadVideoError" class="content-before-share">Xでシェアして5ポイントをゲットしよう！</p>

              <button
                v-if="isVideoComplete"
                class="btn-modal-recommend"
                @click="handleShare({ isWatchVideo: true })"
                v-html="'シェアしてポイントゲット'"
              />

              <button
                v-if="!isShare && !isVideoComplete"
                v-show="!isShowSpinner"
                class="btn-modal-recommend"
                @click="handleShare({ isWatchVideo: false })"
                v-html="'動画を見ないでシェア'"
              />

              <button
                v-if="(isShare || isVideoComplete) && !isActiveVideo"
                class="btn-modal-recommend"
                @click="closeModalRecommended"
                v-html="'閉じる'"
              />
            </template>
      </div>
    </Modal>

    <!-- MODAL AD VIDEO -->
    <div :id="adVideoRender"></div>
    <button id="reward-error-get-point" class="hidden" @click="onRewardError"></button>
    <button
      id="reward-complete-get-point"
      class="hidden"
      @click="onRewardComplete"
    ></button>
    <button id="reward-cancel-get-point" class="hidden" @click="onRewardCancel"></button>

    <Loading v-if="isShowSpinner"/>
  </main>
</template>

<script>
// Store
import store from '@/store'
import { RESPONSE_STATUS } from '@/enum/response'
import { mapActions, mapState } from 'vuex'
// Components
import Sidebar from '@/layouts/Sidebar'
import VideoAdvertise from '@/components/advertising-detail/VideoAdvertise'
import SimilarAdvertisement from '@/components/advertising-detail/SimilarAdvertisement'
import Modal from '@/components/advertising-detail/Modal'
import ListItem from '@/components/ListItem.vue'

// Other
import { PlusIcon, ClipboardIcon, ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons'
import InputField from '@/components/form/InputField'
import InputDatepicker from '@/components/form/InputDatepicker'
import TextAreaField from '@/components/form/TextAreaField'
import Common from '@/mixins/common.mixin'
import VideoAds from '@/mixins/videoAds.mixin'
import { DEVICE } from '@/enum/device'

// QR code
import QrCodeVue from 'qrcode.vue'
import moment from 'moment'
import { isMobile, isAndroid } from 'mobile-device-detect'

export default {
  name: 'AdvertisingDetail',

  components: {
    TextAreaField,
    InputDatepicker,
    InputField,
    Modal,
    VideoAdvertise,
    SimilarAdvertisement,
    Sidebar,
    PlusIcon,
    ClipboardIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    QrCodeVue,
    ListItem
  },

  mixins: [Common, VideoAds],

  data () {
    return {
      currentTab: 1,
      menuTab: [
        {
          id: 1,
          name: '概要'
        },
        {
          id: 2,
          name: '獲得条件'
        },
        {
          id: 3,
          name: '注意事項'
        }
      ],
      form: {
        title: '',
        advertise_id: this.$route.params.id,
        deadline_datetime: '',
        note: ''
      },
      typeQA: 2,
      flagModalQA: false,
      flagModalClickUrl: false,
      flagModalShare: false,
      flagModalBlockTracking: false,
      flagModalAddAdv: false,
      flagNotifyShare: false,
      showNoti: false,
      errors: [],
      currentURL: window.location.href,
      currentDate: new Date(),
      windowReference: null,
      shareLineUrl: '',
      flagModalCautionAdv: false,
      flagModalRecommend: false,
      adVideoDefineAdSlot: process.env.VUE_APP_VIDEO_ADVERTISE_SLOT || '',
      adVideoRender: process.env.VUE_APP_VIDEO_ADVERTISE_RENDER || ''
    }
  },

  watch: {
    'isDoneWaiting' (isDone) {
      if (isDone && !this.isLoadVideo) {
        this.onHideSpinner()
        this.videoComplete()

        if (!this.miAPI) return
        this.miAPI.remove()
      }
    }
  },

  async mounted () {
    const params = {
      with: 'tags,company'
    }
    await store.dispatch('advertises/getAdvByID', [this.$route.params.id, params])

    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }

    await store.dispatch('advertises/getListRecommended', [this.$route.params.id, {}])

    // set advertise id when change params id
    this.form.advertise_id = this.detail ? this.detail.id : this.$route.params.id

    // detect link to share line
    const urlParam = this.isAuth ? (window.location.href + '?invite=' + this.profile.ref_code) : (window.location.href)
    this.shareLineUrl = window.location.origin + `/share-seo/sns/${this.$route.params.id}?target_url=${urlParam}`

    // check show modal notify share complete when from page call back
    const notifyShare = localStorage.getItem('notifySuccess') || null
    if (notifyShare) {
      // this.flagNotifyShare = true
      this.setAdvOpenModal(this.$route.params.id)
      localStorage.removeItem('notifySuccess')
    }
    if (this.advIdOpenShared === this.$route.params.id && !this.flagErrorShare) {
      this.flagModalRecommend = true
      store.dispatch('advertises/getListRecommendModal')
    }

    // init adv video
    this.onInitReward()
  },

  computed: {
    ...mapState('auth', ['token', 'profile']),
    ...mapState('advertises', ['detail', 'recommendedList', 'listRecommendModal', 'isKeepModal', 'advIdOpenShared', 'isShare']),
    ...mapState('category', ['categoryListTitle']),
    ...mapState('shareSns', ['flagErrorShare']),

    textGetPoint () {
      return this.detail && this.detail.text_get_point_setting ? this.detail.text_get_point_description : 'ポイントゲット'
    },
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    },
    isAuth () {
      return this.$store.state.auth.token
    },

    displayTagNew () {
      const now = +this.currentDate
      const start = moment(this.detail.publish_start_datetime).valueOf()
      const startRange = moment(this.detail.publish_start_datetime).add(72, 'hours').valueOf()

      return now >= start && now <= startRange
    },

    displayTagDeadline () {
      const now = +this.currentDate
      const end = moment(this.detail.publish_end_datetime)
      const endRange = moment(this.detail.publish_end_datetime).subtract(72, 'hours')

      return now >= endRange && now <= end
    }
  },

  methods: {
    ...mapActions('cart', ['createCart']),
    ...mapActions('favoriteAds', ['likeAds', 'unlikeAds']),
    ...mapActions('advertises', ['getAdvByID', 'getListRecommended', 'setAdvOpenModal', 'getListRecommendModal']),
    ...mapActions('afad', ['userAFADRequest']),
    ...mapActions('shareSns', ['authorizeTwitter', 'setTwitterError']),

    onStartVideo () {
      this.onLoadVideo()
      this.onHideSpinner()
    },

    onAdError () {
      this.closeModalRecommended()
      this.setTwitterError(true)
      this.onHideSpinner()
    },

    beforeGetPoint () {
      // show modal first time before get point
      if (this.isAuth) {
        const localFlag = localStorage.getItem('flagModalTracking')
        // check device is IOS/safari and show modal first time
        if (this.checkDevice() && (localFlag === null || !localFlag.includes(this.profile.id)) && !this.checkWebview()) {
          this.flagModalBlockTracking = true
        } else {
          // Call API Get point
          if (this.detail.is_caution) {
            this.flagModalCautionAdv = true
            return
          }

          if (this.detail.is_instant_get_point === 1) {
            this.handleShowAdVideo()
          } else {
            this.handleGetPoint()
          }
        }
      } else this.openPageNative('Register')
    },

    getDeviceUsing () {
      if (!isMobile) return DEVICE.web
      else if (isAndroid) return DEVICE.android
      else return DEVICE.ios
    },

    isShowModalClickUrl () {
      const {
        normal_click_url: normalClickUrl,
        campaign_click_url: campaignClickUrl,
        is_campaign: isCampaign,
        campaign_start_datetime: campaignStartDateTime,
        campaign_end_datetime: campaignEndDateTime
      } = this.detail

      const now = new Date()
      const campaignStartTime = new Date(campaignStartDateTime)
      const campaignEndTime = new Date(campaignEndDateTime)

      const notInCampaignTime = isCampaign && (now < campaignStartTime || now > campaignEndTime)

      if (isMobile) return false
      else if (isCampaign) {
        if (notInCampaignTime) {
          if (!normalClickUrl) return true
          else return false
        } else if (!campaignClickUrl) {
          return true
        }
      } else if (!normalClickUrl) return true
      else return false
    },

    async handleModalTracking () {
      let data = localStorage.getItem('flagModalTracking')
      data = data ? JSON.parse(data) : []
      data.push(this.profile.id)
      localStorage.setItem('flagModalTracking', JSON.stringify(data))
      this.flagModalBlockTracking = false
      await this.handleGetPoint()
    },

    async handleGetPoint () {
      const device = this.getDeviceUsing()
      const params = {
        advertise_id: this.detail.id,
        device
      }
      // open new tab on IOS
      if (!this.checkWebview() && this.checkDevice()) {
        this.windowReference = window.open('', '_blank')
      }
      if (this.flagModalCautionAdv) {
        this.flagModalCautionAdv = false
      }

      if (this.isShowModalClickUrl()) {
        this.flagModalClickUrl = true
        return
      }

      await this.userAFADRequest(params).then(result => {
        if (result.success) {
          const disableButton = result.data.data.disable_button
          if (disableButton) {
            // disable get point button
            this.detail.is_disable_get_point = 1
            return
          }

          // check webview to open browser
          const url = result.data.data.url
          if (this.checkWebview()) {
            this.triggerEventApp('OPEN_BROWSER', { url: url })
          } else {
            this.checkDevice() ? this.windowReference.location = url : window.open(url, '_blank')
          }
        } else if (result.status === 400) {
          this.openPageNative('Expired')

          // Check IOS to close tab before change route
          if (this.checkDevice()) {
            this.windowReference.close()
          }
        } else if (result.status === 404) {
          this.openPageNative('PageNotFound')

          if (this.checkDevice()) {
            this.windowReference.close()
          }
        }
      })

      // disable get point button
      if (this.detail.is_instant_get_point === 1) {
        this.detail.is_disable_get_point = 1
      }
    },

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.handleSubmit()
      }
    },

    handleSubmit () {
      this.createCart(this.form).then(result => {
        if (result.status === 201) {
          this.flagModalAddAdv = false
          this.form.title = ''
          this.form.deadline_datetime = ''
          this.form.note = ''
        } else {
          if (this.checkWebview()) {
            this.openPageNative('PageNotFound')
          } else this.$router.push({ name: 'PageNotFound' })
        }
      })
    },

    async handleLike () {
      if (this.detail.is_favorite === 0) {
        await this.likeAds({ advertise_id: this.$route.params.id })
      } else {
        await this.unlikeAds({ advertise_id: this.$route.params.id })
      }
      await this.getAdvByID([this.$route.params.id, { with: 'tags,company' }])
    },

    fetchList (id) {
      // replace router to run created again
      this.$router.replace({
        name: 'AdvertisingDetail',
        params: { id: id }
      }).catch(() => {})
    },

    scrollToTab () {
      const header = document.getElementById('header-tab')
      const yCoordinate = header.getBoundingClientRect().top + window.pageYOffset
      let yOffset = 0

      // check device SP plus offset top is covered by header
      if (window.matchMedia('(max-width: 767px)').matches) {
        yOffset -= 45
      }

      window.scrollTo({
        top: yCoordinate + yOffset,
        behavior: 'smooth'
      })
    },

    async handleShare ({ isWatchVideo = false }) {
      this.showNoti = false
      // open new tab on IOS
      if (this.checkDevice() && !this.checkWebview()) {
        this.windowReference = window.open('', '_blank')
      }

      const targetUrl = this.isAuth ? (window.location.href + '?invite=' + this.profile.ref_code) : (window.location.href)
      const urlParams = targetUrl.includes('?invite')
        ? (targetUrl + '&section_id=' + Date.now()) : (targetUrl + '?section_id=' + Date.now())
      let titleAd = this.detail.title
      const urlShareContent = window.location.origin + `/share-seo/sns/${this.detail.id}?target_url=${urlParams}`

      // check length character share twitter
      // get length of element fixed of twitter share
      const lengthFixed = 51 + urlShareContent.length

      // the maximum number of characters remaining of the title(max character share is 280), discount 10 character = 270
      const maxLengthTitle = 270 - lengthFixed

      // Compact title if over limit share charater of twitter
      if (titleAd && (maxLengthTitle < titleAd.length)) {
        titleAd = titleAd.slice(0, maxLengthTitle) + '...'
      }

      const content = '【PR】ポイ活サイトのCHIBIJOB（ちびじょぶ）でお小遣いGET！！\n' +
        `${titleAd}\n` +
        'ミッション達成でポイントGETしよう！\n' + '#chibijob #ポイ活 #懸賞 #懸賞垢さんと繋がりたい #懸賞仲間募集中\n' + urlShareContent
      const params = {
        type: 1,
        content_share: content,
        device_id: navigator.userAgent,
        watched_video: +isWatchVideo
      }
      // save local storage to push BE
      localStorage.setItem('urlShare', targetUrl)
      localStorage.setItem('titleAd', this.detail.title)
      localStorage.setItem('idAd', this.detail.id)

      // open share on webview
      if (this.checkWebview()) {
        this.windowReference = window.open('', '_self')
      }

      const authTwitter = await this.authorizeTwitter(params)

      if (authTwitter.status === RESPONSE_STATUS.OK) {
        if (authTwitter.data && authTwitter.data.link_share) {
          // check webview to open browser
          if (this.checkWebview()) {
            this.windowReference.location = authTwitter.data.link_share
          } else {
            if (this.checkDevice()) {
              this.windowReference.location = authTwitter.data.link_share
            } else {
              window.open(authTwitter.data.link_share, '_blank')
            }
          }
        } else {
          if (this.checkDevice() || this.checkWebview()) {
            this.windowReference.close()
          }

          this.flagModalShare = false
          // turn on modal share success
          // this.flagNotifyShare = true
          this.flagModalRecommend = true
        }
        this.inActiveVideo()
        this.videoUnComplete()
        this.$store.dispatch('advertises/setHandleShare', true)
      } else if (authTwitter.status === RESPONSE_STATUS.UNPROCESSABLE_CONTENT) {
        // Check IOS to close tab before change route
        if (this.checkDevice() || this.checkWebview()) {
          this.windowReference.close()
        }

        this.flagModalShare = false
        this.flagModalRecommend = false
      }
    },

    handleCloseError () {
      this.setTwitterError(false)
    },

    handleShareLine () {
      // link to line
      let linkLine = 'http://line.me/R/msg/text/?'

      // add content share to link
      linkLine = linkLine + 'ポイ活サイトのCHIBIJOB（ちびじょぶ）でお小遣いGET！！\n' + this.detail.title +
                    '\nミッション達成でポイントGETしよう！\n' + this.shareLineUrl

      // encoded url share
      const encoded = encodeURI(linkLine)

      // event to open browser to open app line
      this.triggerEventApp(
        'OPEN_BROWSER',
        { url: encoded }
      )
    },

    closeModal () {
      this.flagModalShare = false
    },

    openModalShareTwitter () {
      this.flagModalRecommend = true
      this.flagModalShare = false
      this.getListRecommendModal()
      this.setAdvOpenModal(this.$route.params.id)
    },

    closeModalRecommended () {
      this.flagModalRecommend = false
      this.setAdvOpenModal(null)
      this.resetVideoState()
      store.dispatch('advertises/resetModal')
    },

    handleShowAdVideo () {
      const adVideoRender = this.adVideoRender
      const rewardPlayBtns = document.querySelectorAll('button.btn-get-point')

      try {
        window.alm.que.push(function () {
          rewardPlayBtns.forEach((rewardPlayBtn) => {
            rewardPlayBtn.disabled = true
          })
          window.alm.render(adVideoRender)
        })

        window.alm.que.push(function () {
          window.alm.getAdTracker(adVideoRender).then(function (adTracker) {
            adTracker.track(window.alm.Events.AD_LOADING_FAILED, function () {
              //  This section describes what to do when there are no ads or an error occurs.
              //  Michao retrieves ads from several different ad sources, but there are times
              //  when the connection may be slow depending on the specific ad source, and the associated poor ad return rate.
              console.warn('No ads here...')
              const rewardComplete = document.getElementById('reward-complete-get-point')
              rewardComplete.click()
            })

            adTracker.track(window.alm.Events.AD_ERROR, function () {
              console.warn('AD_ERROR')
              const rewardComplete = document.getElementById('reward-complete')
              rewardComplete.click()

              rewardPlayBtns.forEach((rewardPlayBtn) => {
                rewardPlayBtn.disabled = false
              })
            })

            adTracker.track(window.alm.Events.REWARDED, function () {
              /* Describe the reward process here. Customize it as you like. */
              console.log('Rewarded!!!')
              const rewardComplete = document.getElementById('reward-complete-get-point')
              rewardComplete.click()

              rewardPlayBtns.forEach((rewardPlayBtn) => {
                rewardPlayBtn.disabled = false
              })
            })

            adTracker.track(
              window.alm.Events.SOUND_CONFIRMATION_CANCEL,
              function () {
                console.log('CANCEL!!!')
                rewardPlayBtns.forEach((rewardPlayBtn) => {
                  rewardPlayBtn.disabled = false
                })
                const rewardCancel = document.getElementById('reward-cancel-get-point')
                rewardCancel.click()
              }
            )
          })
        })
      } catch {
        const rewardComplete = document.getElementById('reward-complete')
        rewardComplete.click()
      }
    },
    onRewardError () {
      console.warn('reward error')
      this.onInitReward()
      this.handleGetPoint()
    },
    onRewardComplete () {
      this.onInitReward()
      this.$emit('adVideoComplete')
      this.handleGetPoint()
    },
    onRewardCancel () {
      this.onInitReward()
    },
    onInitReward () {
      const adVideoDefineAdSlot = this.adVideoDefineAdSlot
      const adVideoRender = this.adVideoRender
      window.alm = window.alm || { que: [] }
      window.alm.que.push(function () {
        window.alm.defineAdSlot(adVideoDefineAdSlot, adVideoRender)
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'InviteFriend' && store.state.advertises.isKeepModal && to.name !== 'AdvertisingDetail') {
      store.dispatch('advertises/resetModal')
    } else if (to.name === 'InviteFriend' && store.state.advertises.advIdOpenShared) {
      store.dispatch('advertises/resetModal')
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
.ps-banner-advertising {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 36px 0;
  position: relative;
  z-index: 1;
  @media #{$info-phone} {
    padding-top: 81px;
    position: relative;
    margin-bottom: 90px;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 120px;
    }
  }
  &.padding-webview {
    padding-top: 40px;
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -2;
    backdrop-filter: blur(100px);
    background-color: rgba(255, 255, 255, 0.8);
  }
  .list-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media #{$info-phone} {
      position: absolute;
      bottom: -66px;
      width: 100%;
      justify-content: space-between;
    }
    .btn-icon {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #ffffff;
      margin: 0 8px;
      cursor: pointer;
      @media #{$info-phone} {
        background: #F5F5F5;
        margin: 0 8px 0 0;
      }
      &.btn-QA {
        @media #{$info-phone} {
          display: none;
        }
      }
    }
    .btn-like {
      &.active {
        svg {
          path {
            fill: #E50023
          }
        }
      }
    }
    .btn-buy {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #282828;
      width: 196px;
      height: 36px;
      background: transparent;
      cursor: pointer;
      @include font-size(14px);
      font-weight: bold;
      border-radius: 18px;
      position: relative;
      margin-left: 40px;
      color: #282828;
      @media #{$info-phone} {
        margin-left: 12px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 18px;
        background: #444444;
        left: -24px;
        top: 50%;
        transform: translateY(-50%);
        @media #{$info-phone} {
          content: unset;
        }
      }
      svg {
        margin-right: 6px;
      }
    }
  }
  .content-advertising {
    display: flex;
    align-items: center;
    @media #{$info-phone} {
      flex-direction: column;
      align-items: flex-start;
    }
    figure {
      padding: 6px;
      border-radius: 6px;
      background: #ffffff;
      width: 228px;
      height: 228px;
      margin-right: 54px;
      flex-shrink: 0;
      @media #{$info-phone} {
        width: 132px;
        height: 132px;
        margin: 0 0 24px;
        align-self: center;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background: #ffffff;
        object-position: center;
      }
    }
    .description-ads {
      h2 {
        font-family: $font-family-title;
        @include font-size(28px);
        @media #{$info-phone} {
          font-size: 20px;
        }
      }
      p {
        font-family: $font-family-title;
        @include font-size(18px);
        margin-bottom: 18px;
        @media #{$info-phone} {
          font-size: 16px;
        }
      }
      .tag {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        white-space: pre-line;
        span {
          padding: 1px 10px;
          background: #6F8784;
          border-radius: 10px;
          color: #ffffff;
          margin-right: 12px;
          word-break: break-word;
          word-spacing: normal;
          @include font-size(14px);
          margin-bottom: 7px;
        }
        .tag-red {
          background: #FF0000;
        }
        .tag-orange {
          background: #FF6600;
        }
      }
    }
  }
  .bg-ripple {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}
.home-content {
  background: #ffffff;
  padding-top: 45px;
  padding-bottom: 96px;
  position: relative;
  @media #{$info-phone} {
    display: block;
    padding-top: 0;
    padding-bottom: 72px;
  }
  &.padding-webview {
    padding-bottom: 80px;
  }
  .sidebar-advertising {
    float: left;
    position: sticky;
    top: 10px;
    margin-top: 20px;
    @media #{$info-tablet-horizontal} {
      padding-left: 18px;
    }
  }
  .main-content {
    margin-left: 267px;
    padding: 0 0 0 15px;
    min-height: 100vh;
    @media #{$info-phone} {
      padding: 0;
      margin-left: 0;
      border-top: 1px solid #444444;
    }
    .display-point {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 15px;
      margin-bottom: 30px;
      @media #{$info-phone} {
        flex-direction: column-reverse;
        align-items: center;
        margin: 21px 0 48px;
      }
      p {
        @media #{$info-phone} {
          text-align: center;
        }
        .label-rate {
          font-family: $font-default;
          @include font-size(14px);
          margin-right: 24px;
          @media #{$info-phone} {
            display: block;
            margin-right: 0;
            font-size: 14px;
          }
        }
        strong {
          font-family: $font-label;
          font-style: italic;
          @include font-size(48px);
          color: #6F8784;
          font-weight: normal;
          line-height: 1;
          @media #{$info-tablet-vertical} {
            @include font-size(40px);
          }
          @media #{$info-phone} {
            font-size: 36px;
          }
          span {
            @include font-size(25px)
          }
        }
      }
      .btn-login {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #282828;
        @include font-size(14px);
        margin-top: 10px;
        @media #{$info-phone} {
          margin-top: 20px;
          font-size: 16px;
        }
        &:hover {
          text-decoration: none;
        }
        svg {
          margin-right: 4px;
        }
      }
    }
    .block-get-point {
      width: 100%;
      max-width: 420px;
    }
    .btn-get-point {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 52px;
      font-family: $font-family-title;
      color: #ffffff;
      cursor: pointer;
      @include font-size(24px);
      background: #7C934C;
      border-radius: 6px;
      line-height:1 ;
      &.only-sp {
        display: none;
        @media #{$info-phone} {
          display: flex;
        }
      }
      @media #{$info-tablet-horizontal} {
        max-width: 320px;
      }
      @media #{$info-tablet-vertical} {
        // max-width: 210px;
        max-width: 320px;
      }
      @media #{$info-phone} {
        margin: 15px 0 0;
        max-width: 100%;
        font-size: 24px;
      }
      // style btn fix on webview
      &.fix-btn-get {
        position: fixed;
        bottom: 10px;
        z-index: 20;
        width: calc(100% - 36px);
        margin: auto;
      }
      img {
        margin-right: 24px;
      }

      a {
        color: #ffffff;
        @media #{$info-tablet-vertical} {
          @include font-size(20px)
        }
      }
    }
    .btn-get-point[disabled] {
      background: #444444;
      opacity: 0.4;
      cursor: not-allowed;
    }
    .wrap-process {
      display: flex;
      align-items: stretch;
      background: rgba(203, 203, 198, 0.4);
      border-radius: 6px;
      margin-bottom: 68px;
      @media #{$info-phone} {
        flex-direction: column;
        background: unset;
        position: relative;
        margin-bottom: 30px;
      }
      &:after {
        @media #{$info-phone} {
          content: '';
          position: absolute;
          width: -100%;
          left: -100%;
          right: -100%;
          height: 100%;
          background: rgba(203, 203, 198, 0.4) ;
        }
      }
      .label-process {
        width: 33.3%;
        padding: 21px 18px;
        word-break: break-all;
        @include font-size(16px);
        @media #{$info-phone} {
          width: 100%;
          z-index: 1;
        }
        &:not(:last-child) {
          border-right: 1px dotted #444444;
          @media #{$info-phone} {
            border-right: 0;
            border-bottom: 1px dotted #444444;
          }
        }
        h4 {
          font-family: $font-family-title;
          font-weight: normal;
          text-align: center;
          @media #{$info-phone} {
            font-size: 14px;
            text-align: left;
          }
        }
        p {
          word-break: break-word;
        }
      }
    }
  }
}
.modal-qr {
  background: #E7E5DE;
  padding: 10px 15px 62px;
  border-radius: 6px;
  p {
    @include font-size(24px);
    font-family: $font-family-title;
    margin-bottom: 36px;
    @media #{$info-phone} {
      font-size: 20px;
    }
  }
  .qa-code {
    background: #FFF;
    padding: 15px;
    display: inline-block;
    line-height: 1;
  }
}
.modal-share {
  background: #E7E5DE;
  border-radius: 6px;
  padding: 10px 30px 20px;
  // disable content if there is a modal on top
  &.bg-disabled {
    background-color: rgba(0,0,0,0.4);
  }
  @media #{$info-phone} {
    padding: 18px;
  }
  .title-modal {
    margin-bottom: 43px;
    @include font-size(24px);
    font-family: $font-family-title;
    @media #{$info-phone} {
      font-size: 20px;
    }
  }
  .btn-social {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0;
    border-radius: 6px;
    margin: 24px auto;
    max-width: 456px;
    background: #F0F9FF;
    border: 2px solid #1D9BF1;
    color: #1C9BF1;
    @include font-size(25px);
    @media #{$info-phone} {
      font-size: 22px;
      margin: 18px auto;
    }
    &.btn-line {
      background: #F2FFF7;
      border: 2px solid #2DC755;
      color: #06C755;
      display: none;
      @media #{$info-phone} {
        display: flex;
      }
    }
    &.twitter {
      border: 2px solid #000000;
      background-color: rgba(0, 0, 0, 0.05);
      color: #000000;
    }
    img {
      margin-right: 24px;
      width: 36px;
      @media #{$info-phone} {
        width: 25px;
        margin-right: 8px;
      }
    }
  }
  .warning-share {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 52px 20px;
    background: #FFFFFF;
    width: 87%;
    position: absolute;
    left: 50%;
    top: 50%;
    min-height: 75%;
    z-index: 1;
    @include font-size(25px);
    transform: translate(-50%, -50%);
    @media #{$info-phone} {
      font-size: 20px;
      padding: 20px 10px;
      justify-content: center;
    }
    .confirm {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      cursor: pointer;
      background: #C66C44;
      color: #ffffff;
      border-radius: 6px;
      @media #{$info-phone} {
        margin-top: 30px;
      }
    }
  }
  .wrap-copy {
    margin-top: 40px;
    input {
      background: #FFFFFF;
    }
  }
}
.modal-block-tracking {
  text-align: left;
  h3 {
    text-align: center;
    color: #FF0000;
    margin-bottom: 20px;
    font-weight: normal;
    @include font-size(24px);
    font-family: $font-family-title;
    @media #{$info-phone} {
      font-size: 20px;
    }
  }
  .text-des {
    margin-bottom: 40px;
    @include font-size(20px);
    @media #{$info-phone} {
      font-size: 12px;
      margin-bottom: 35px;
    }
  }
  .note {
    margin-bottom: 86px;
    @media #{$info-phone} {
      margin-bottom: 45px;
    }
    span {
      display: block;
      background: #D1D1D1;
      padding: 6px 17px 4px;
      border-radius: 6px;
      @include font-size(18px);
      margin-bottom: 14px;
      @media #{$info-phone} {
        font-size: 14px;
      }
    }
    p {
      @include font-size(18px);
      margin: 0 0 20px 16px;
      @media #{$info-phone} {
        font-size: 12px;
        margin: 0 0 10px 0;
      }
    }
    img {
      width: 100%;
    }
    &.sp {
      img {
        max-width: 655px;
      }
    }
    &.pc {
      max-width: 829px;
    }
  }
}
.btn-confirm {
  @include font-size(24px);
  background: #C66C44;
  color: #ffffff;
  border-radius: 6px;
  margin: auto;
  height: 48px;
  display: flex;
  align-items: center;
  max-width: 434px;
  width: 100%;
  justify-content: center;
  @media #{$info-phone} {
    font-size: 16px;
  }
}
.form__cart {
  padding: 22px 25px;
  border-radius: 6px;
  background: #E7E5DE;
  text-align: left;
  margin-bottom: 26px;
  @media #{$info-phone} {
    padding: 17px 18px;
    margin-bottom: 20px;
  }
  .form__control {
    margin-bottom: 20px;
    @media #{$info-phone} {
      margin-bottom: 13px;
    }
    label {
      margin-bottom: 10px;
      display: inline-block;
      @include font-size(18px);
      @media #{$info-phone} {
        font-size: 16px;
        line-height: 1;
        margin-right: 10px;
      }
      .required {
        color: #C66C44;
        @include font-size(14px);
        font-weight: bold;
        line-height: 1;
        margin-left: 10px;
        align-self: center;
        display: inline-block;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &--select {
      .form__control__input {
        display: flex;
        align-items: center;
        @media #{$info-phone} {
          flex-direction: column;
        }
        .select {
          flex: 1;
          @media #{$info-phone} {
            width: 100%;
          }
        }
        .title-select-ads {
          @include font-size(20px);
          padding: 10px;
          word-break: break-all;
          pointer-events: none;
          opacity: .45;
          background: #fff;
          line-height: 1.3;
          border-radius: 6px;
          flex: 1;
          @media #{$info-phone} {
            width: 100%;
          }
        }
        a {
          width: 152px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $font-family-title;
          @include font-size(16px);
          background: #444444;
          border-radius: 3px;
          color: #ffffff;
          cursor: pointer;
          margin-left: 17px;
          @media #{$info-phone} {
            margin: 14px 0 0;
            height: 44px;
            font-size: 16px;
          }
        }
      }
    }
    &__input {
      .time {
        @media #{$info-phone} {
          width: 100%;
        }
      }
    }
  }
}
.btn__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-title;
  max-width: 357px;
  width: 100%;
  height: 51px;
  background: #C66C44;
  border-radius: 6px;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
  @media #{$info-phone} {
    height: 44px;
    font-size: 16px;
  }
}
.modal-notify {
  @include font-size(24px);
  font-weight: bold;
}
.btn-caution__submit {
  margin-top: 25px;
  font-size: 20px;
  img {
    margin-right: 15px;
  }
}
.btn-social-hidden {
  display: none !important;
}
.content-before-share {
    padding-top: 20px;
    font-weight: bold;
    @include font-size(20px);
    @media #{$info-phone} {
      font-size: 14px;
    }
}
.btn-qr-close {
  @include font-size(20px);
  cursor: pointer;
  width: 100%;
  max-width: 280px;
  height: 52px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-title;
  color: $white;
  background-color: $blue-1;
  border-radius: 6px;
  line-height: 1;
  @media #{$info-phone} {
    @include font-size(16px);
  }
}
</style>
